import { createApp } from "vue";
import App from "./App.vue";

import { createPinia } from 'pinia';
import piniaPersist from 'pinia-plugin-persist-uni'

const piniaStore = createPinia();
piniaStore.use(piniaPersist);

import uView from "vk-uview-ui"

import CBody from "@/components/CustomBody.vue"

import './static/font/font.scss'


const app = createApp(App)
	.use(piniaStore)
	.use(uView)
	.component('c-body', CBody)

app.mount('#app')
