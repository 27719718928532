import {defineStore} from "pinia";
import {ref} from "vue";
import {LocationInfo} from "@/util/Api";

export enum RoleType {
	NORMAL,
	ADMINISTRATOR
}

export const useUserStore = defineStore('user', () => {

	const userId = ref("")

	const name = ref("")
	const phoneNumber = ref("")
	const role = ref(RoleType.NORMAL)
	const location = ref<LocationInfo|null>()

	function setUserId(id: string) {
		userId.value = id;
	}

	function setName(remarkName: string) {
		name.value = remarkName;
	}

	function setPhoneNumber(phone: string) {
		phoneNumber.value = phone;
	}

	function setRole(roleType: number) {
		if(roleType === 0)
			role.value = RoleType.NORMAL;
		else
			role.value = RoleType.ADMINISTRATOR;
	}

	function isAdmin() {
		return role.value === RoleType.ADMINISTRATOR;
	}

	function setLocation(info: LocationInfo) {
		location.value = info;
	}

	return {
		userId, setUserId,
		name, setName,
		phoneNumber, setPhoneNumber,
		setRole,
		isAdmin,
		location, setLocation
	}

})