<template>
    <uni-nav-bar backgroundColor="#1F1E1E"
                 height="100rpx"
                 leftWidth="160rpx"
                 rightWidth="590rpx"
                 :border="false" :statusBar="true"
                 color="#FFFFFF">
        <template #left>
            <image class="title-flag" :src="loadImg('logo.png')"/>
        </template>
        <template #right>
            <uni-breadcrumb class="ly-menu" separator="/">
                <uni-breadcrumb-item v-for="(item, index) in menus" :key="index" 
                                     @click="jumpPage(index)" >
                    <text class="ly-menu__item" :style="{ 'color': `${item.fontColor}` }">
                        {{ item.title }}
                    </text>
                </uni-breadcrumb-item>
            </uni-breadcrumb>
        </template>
    </uni-nav-bar>
    <uni-drawer class="c-drawer" ref="drawer" mode="left" :mask-click="false">
        <view class="c-drawer-content">
            <view class="c-drawer-content__title">
                <image class="title-flag" :src="loadImg('logo.png')"/>
                <uni-icons class="c-drawer-content__title-icon" type="closeempty" size="26" color="#ffffff" @click="close"></uni-icons>
            </view>
            <view class="c-list">
                <view class="c-list-item__content"
                      v-for="(item, index) in menus" :key="index"
                      @click="jumpPage(index)">
                    <view class="c-list-item__label" :style="{'--c-color': item.fontColor}">
                        <text class="c-list-item__label-title">{{item.title}}</text>
                        <text class="c-list-item__label-subtitle">{{item.subtitle}}</text>
                    </view>
                    <uni-icons type="forward" color="#ffffff" size="24"/>
                </view>
            </view>
        </view>
    </uni-drawer>
</template>

<script setup lang="ts">

import {computed, ref} from "vue";
import {useUserStore} from "@/store/user";
import {loadImg} from "@/util/Res";

const MENU_ITEMS = [
    {title: '首页', subtitle: 'INDEX', fontColor: '#ffffff',  navPath: '/pages/main/main'},
    {title: '墓志', subtitle: 'EPITAPH', fontColor: '#ffffff',  navPath: '/pages/epitaph/epitaph'},
    {title: '家族', subtitle: 'FAMILY', fontColor: '#ffffff',  navPath: '/pages/family/family?index=0'}
]


const drawer = ref<any>()

const menus = computed(() => {
    let pages = getCurrentPages();
    let curRoute = pages[pages.length-1].route
    let items = MENU_ITEMS.map(value => {
        if(curRoute && value.navPath.includes(curRoute)) {
            value.fontColor = '#C79C53';
        }
        else {
            value.fontColor = '#fff'
        }
        return value;
    })

    if(useUserStore().isAdmin()) {
        items.push(
        { title: '我的', subtitle: 'MY', fontColor: '#ffffff', navPath: '/pages/my/my' })
    }

    return items;
})

function open() {
    drawer.value.open()
}

function close() {
    drawer.value.close()
}

function jumpPage(index: number) {
    close();
    uni.navigateTo({url: menus.value[index].navPath})
}

</script>

<style scoped lang="scss">

$custom-color: var(--c-color);

.title-flag {
    width: 112rpx;
    height: 50rpx;
    margin: 16rpx;
    border-radius: 5rpx;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ly-menu {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .ly-menu__item{
        font-size: 34rpx!important;
        color: white;
    }
}


.c-drawer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .c-drawer-content {
        width: 100%;
        height: auto;
        background-color: #1f1e1e;
        display: flex;
        flex-direction: column;

        .c-drawer-content__title {
            margin-top: 59rpx;
            margin-bottom: 20rpx;
            margin-left: 40rpx;
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: row;
            align-items: center;

            .c-drawer-content__title-icon {
                position: absolute;
                right: 36rpx;
            }
        }

        .c-list {
            width: 90%;
            height: auto;
            margin: 0 20rpx;
            border-top: 1rpx solid #FFFFFF14;

            .c-list-item__content {
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: row;
                align-items: center;

                .c-list-item__label {
                    flex: 1;
                    height: auto;
                    padding: 32rpx;
                    color: $custom-color;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    border-bottom: 1rpx solid #FFFFFF14;

                    .c-list-item__label-title {
                        font-size: 32rpx;
                    }

                    .c-list-item__label-subtitle {
                        margin-left: 20rpx;
                        font-size: 28rpx;
                        opacity: 0.16;
                    }
                }
            }
        }
    }

}

::v-deep .uni-drawer__content{
    width: 100% !important;
    height: 100% !important;
    background-color: #a6a6a6;
}

</style>