<script setup lang="ts">
import { onLaunch, onShow, onHide } from "@dcloudio/uni-app";
onLaunch(() => {
  console.log("App Launch");
});
onShow(() => {
  console.log("App Show");
});
onHide(() => {
  console.log("App Hide");
});
</script>
<style lang="scss">
@import "vk-uview-ui/index.scss";

html, body {
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
	  display: flex;
	  justify-content: center;
	  align-items: center;
}

* {
    box-sizing: border-box;
}



uni-page-head {
    display: none;
}


page{
    width: 100vw;
    max-width: 1080px!important;
    height: 100%;
    background-color: #F5F5F5!important;
    overflow-y: auto;
}

@media (min-width:1080px){
	page{
		padding: 0 140rpx !important;
	};
}

</style>

<style lang="css">
page::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
    -webkit-appearance: none;
    background: transparent!important;
}

scroll-view::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
    -webkit-appearance: none;
    background: transparent!important;
}

</style>
