<template>
    <view class="ly-footer">
        <uni-breadcrumb class="ly-footer-menu" separator="/">
            <uni-breadcrumb-item v-for="(item,index) in menus" :key="index" @click="jump(index)">
                <text class="ly-footer-menu__item" :style="{'color': `${item.fontColor}`}">
                    {{item.title}}
                </text>
            </uni-breadcrumb-item>
        </uni-breadcrumb>
        <view class="ly-footer-desc">
            <text class="ly-footer-desc__text">
                Copyright ©️ 2023 墓志 保留所有权利
            </text>
            <view style="display: flex; flex-direction: row;">
                <uni-link
                    href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010802013031"
                    text="公安网备：33010802013031"
                    color="#007BFF"
                    :showUnderLine="false"
                    :fontSize="8"
                />
                <uni-link
                    style="margin-left: 25rpx;"
                    href="https://beian.miit.gov.cn/"
                    text="浙ICP备2023006019号-1"
                    color="#007BFF"
                    :showUnderLine="false"
                    :fontSize="8"
                />
            </view>
        </view>
    </view>
</template>

<script setup lang="ts">


import {computed} from "vue";

const MENU_ITEMS = [
    {title: '首页', subtitle: 'INDEX', fontColor: '#ffffff',  navPath: '/pages/main/main'},
    {title: '墓志', subtitle: 'EPITAPH', fontColor: '#ffffff',  navPath: '/pages/epitaph/epitaph'},
    {title: '家族', subtitle: 'FAMILY', fontColor: '#ffffff',  navPath: '/pages/family/family?index=0'},
]

const menus = computed(() => {
    let pages = getCurrentPages();
    let curPageRoute = pages[pages.length-1].route
    return MENU_ITEMS.map(value => {
        if(curPageRoute && value.navPath.includes(curPageRoute)) {
            value.fontColor = '#C79C53';
        }
        else {
            value.fontColor = '#fff';
        }
        return value;
    })
})

function jump(index: number) {
    menus.value[index].fontColor='#C79C53';
    uni.redirectTo({
        url: menus.value[index].navPath
    })
}

</script>

<style scoped lang="scss">
.ly-footer {
    width: 100%;
    height: auto;
    padding: 0 20rpx;
    background-color: #1f1e1e;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
	font-family: 'DreamHanSerifCN' !important;
	font-style: normal;


    .ly-footer-menu {
        flex: 1;
        width: 100%;
        display: flex;
        padding: 20rpx 50rpx;
        justify-content: center;
        align-items: center;

        .ly-footer-menu__item{
            margin: 0 20rpx;
            font-size: 34rpx!important;
            color: white;
        }
    }

    .ly-footer-desc {
        width: 100%;
        padding: 10rpx 0;
        border-top: 1rpx solid #FFFFFF14;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    .ly-footer-desc__text {
            font-size: 14rpx;
            color: white;
            opacity: 0.5;
        }
    }

}
</style>